@import url("https://use.typekit.net/qif4fcq.css");
@import "~antd/dist/antd.less";
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.anticon svg {
  display: block;
}
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 18px !important;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #ddd;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0em;
}
.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.main-menum.ant-menu-horizontal {
  justify-content: center !important;
  line-height: 50px !important;
  margin-left: -15px;
  margin-right: -15px;
  // background-color: rgba(0, 0, 0, 0.1) !important;
  // background-color: #854516 !important;
  //box-shadow: 0 0 5px #ddd;
  background-color: transparent;
}

.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  background-color: white !important;
  min-height: calc(100vh - 188px);
}
.ant-layout-header {
  height: unset !important;
  background-color: white;
  padding: 0 15px !important;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-menu-item-selected {
  font-weight: 700;
  background-color: transparent !important;
}
.amenu {
  background-color: transparent;
  margin-top: 10px;
}
.amenu .ant-menu-item-selected {
  background-color: white !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-drawer-body {
  padding: 0;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  background-color: #eeeeee !important;
}
.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 15px 0;
}
.signatureCanvas {
  border: 1px solid #ddd;
  width: 100%;
  height: 300px;
}
.nested .ant-table table {
  background-color: #f0f9ff !important;
}
.nested .ant-table-thead > tr > th {
  background-color: #e0f2fe !important;
  border-bottom: 1px solid #ccc;
  color: #0891b2 !important;
  font-weight: 600;
}

@primary-color: #0EA5E9;